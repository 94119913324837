import { StyleSheet } from 'react-native';

const CombinedStyles = (colors) => StyleSheet.create({
  header: {
    flex: 0,
    flexDirection: 'row',
    paddingVertical: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 3,
    borderColor: colors.secondary,
    backgroundColor: colors.tertiary,
  },
  headerLogo: {
    width: 300,
    height: 200,
    resizeMode: 'contain',
  },

  // Google Login Styles
  googleLoginContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 32,
    marginTop: 50,
    backgroundColor: colors.tertiary,
  },
  googleLoginLogoContainer: {
    marginBottom: 32,
  },
  googleLoginTitle: {
    fontSize: 24,
    marginBottom: 32,
    fontWeight: 'bold',
    color: colors.primary,
  },
  googleLoginButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  googleLoginButton: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderRadius: 24,
    backgroundColor: colors.secondary,
    flexDirection: 'row',
    alignItems: 'center',
  },
  googleLoginButtonText: {
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: 10,
    color: colors.tertiary,
  },
  googleLoginIconContainer: {
    backgroundColor: '#FFF',
    borderRadius: 24,
    padding: 4,
  },
  googleLoginIcon: {
    width: 32,
    height: 32,
  },

  // User Setup Styles
  userSetupContainer: {
    flex: 1,
    padding: 16,
    backgroundColor: colors.tertiary,
  },
  userSetupTabContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 16,
  },
  userSetupTab: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderRadius: 24,
  },
  userSetupActiveTab: {
    borderBottomWidth: 2,
    borderBottomColor: '#FFF',
  },
  userSetupTabText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  userSetupContentContainer: {
    flex: 1,
    padding: 16,
  },
  userSetupSectionTitle: {
    fontSize: 20,
    marginBottom: 8,
  },
  userSetupFieldLabel: {
    fontSize: 16,
    marginBottom: 4,
  },
  userSetupInput: {
    height: 40,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 8,
    marginBottom: 16,
  },
  addField: {
    backgroundColor: colors.primary, 
    paddingVertical: 6,             
    paddingHorizontal: 12,          
    borderRadius: 4,             
    alignSelf: 'flex-start',       
    marginBottom: 32,                
  },
  addFieldText: {
    color: 'white',           
    fontSize: 14,                
  },
  saveButton: {
    backgroundColor: colors.primary,
    paddingVertical: 10,   
    paddingHorizontal: 20,
    borderRadius: 8,
    alignSelf: 'center',
    marginTop: 20,
    width: 250,          
    marginBottom: 20,
  },
  
  saveButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center', 
  },

  testButton: {
    marginTop: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: colors.primary,
    borderRadius: 8,
  },
  testButtonText: {
    color: 'white',
    fontSize: 16,
    textAlign: 'center',
  },
});

export default CombinedStyles;
