import { createStore, combineReducers, } from 'redux'
import systemStore from '../reducers/SystemReducer'
import chatStore from '../reducers/ChatReducer'

const rootReducer = combineReducers({
  systemStore,
  chatStore,
})

const configureStore = () => { return createStore(rootReducer) }

export default configureStore
