import React, { useState, useEffect } from 'react';
import { View, Text, Pressable, ScrollView, Image, FlatList } from 'react-native';
import { connect } from 'react-redux';
import DynamicFieldWithDescription from '../components/Profile/DynamicFieldwithDescription';
import FieldWithDescription from '../components/Profile/FieldWithDescription';
import CombinedStyles from './CombinedStyles';
import { crawlWebsite } from '../components/Profile/crawlWeb';
import * as Yup from 'yup';


const mapStateToProps = (state) => ({
    systemStore: state.systemStore,
});

const UserSetup = ({ systemStore }) => {
    const { colors } = systemStore;
    const [activeTab, setActiveTab] = useState('Profile');
    const [logoUrl, setLogoUrl] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [signupUrl, setSignupUrl] = useState('');
    const [aiQuestions, setAiQuestions] = useState(['']);
    const [aiDescription, setAiDescription] = useState('');
    const [products, setProducts] = useState(['']);
    const [gtmId, setGtmId] = useState('');
    const [leadScoring, setLeadScoring] = useState(['']);
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null); 
    
    const styles = CombinedStyles(colors);

    // PROFILE
    const addProduct = () => setProducts([...products, '']);
    const addQuestion = () => setAiQuestions([...aiQuestions, '']);
    const addLeadScoring = () => setLeadScoring([...leadScoring, '']);

    const deleteItem = (index, setState, state) => {
        const newState = state.filter((_, i) => i !== index);
        setState(newState);
    };

    const [isHovered, setIsHovered] = useState(false);


    const userSetupSchema = Yup.object().shape({
        logoUrl: Yup.string().url('Enter a valid URL for the logo.').required('Logo URL is required'),
        websiteUrl: Yup.string().url('Enter a valid URL for the website.').required('Website URL is required'),
        signupUrl: Yup.string().url('Enter a valid URL for signup.').required('Signup URL is required'),
        aiQuestions: Yup.array().of(Yup.string().required('Each AI question cannot be empty')),
        aiDescription: Yup.string().required('AI description is required'),
        products: Yup.array().of(Yup.string().required('Each product description cannot be empty')),
        gtmId: Yup.string().matches(/^GTM-\w+$/, 'Enter a valid Google Tag Manager ID (e.g., GTM-XXXXXX)'),
        leadScoring: Yup.array().of(Yup.string().required('Each lead scoring criteria cannot be empty')),
    });


    const handleSave = async () => {
        const dataToSave = {
            logoUrl,
            websiteUrl,
            signupUrl,
            aiQuestions,
            aiDescription,
            products,
            gtmId,
            leadScoring,
        };
          
        // Validate data entered using Yup
        try {
            await userSetupSchema.validate(dataToSave, { abortEarly: false }); // abortEarly: false collects all errors
          
            // If valid, proceed with crawling and saving
            const crawledData = await crawlWebsite(websiteUrl);
            const combinedData = { ...dataToSave, crawledData };
          
            // Make API request to save data
            const response = await fetch('/api/saveUserData', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(combinedData),
            });
          
            if (response.ok) {
                const result = await response.text();
                console.log(result); // Handle success
            } else {
                const errorText = await response.text();
                console.error('Error saving data:', errorText);
            }
            } catch (validationError) {
              if (validationError.name === 'ValidationError') {
                validationError.inner.forEach(err => {
                  console.error(err.message); // Handle each validation error (show on UI as needed)
                });
            } else {
                console.error("Error while crawling or saving:", validationError);
            }
        }
    };
    
    // CHAT 

    // Make API call to fetch chat sessions       *** TO BE FURTHER TESTED WHEN CHAT DATA IS AVAILABLE ***
    useEffect(() => {
        // Fetch chat sessions data from API (update the URL based on your endpoint)
        fetch('/api/chatSessions')
            .then(response => response.json())
            .then(data => setChats(data))
            .catch(error => console.error('Error fetching chat sessions:', error));
    }, []);

    // Function to render each chat item         *** RENDERING CHAT EXAMPLE DATA FROM SERVER index.js ****
    const renderChatItem = ({ item }) => (
        <Pressable
            style={{ padding: 16, borderBottomWidth: 1, borderColor: colors.secondary}}
            onPress={() => setSelectedChat(item)} // Set the selected chat for viewing details
        >
            <Text style={{ color: colors.primary, fontSize: 16 }}>Chat ID: {item.chatId}</Text>
            <Text style={{ color: "#2F4858", fontSize: 14 }}>Preview: {item.previewText}</Text>
            <Text style={{ color: colors.secondary, fontSize: 12 }}>{item.timestamp}</Text>
        </Pressable>
    );
    
    return (
        <View style={{ position: 'absolute', width: '100%', height: '100%' }}>
        {/* Header Section */}
        <View style={styles.header}>
            <Image source={require('../assets/images/grantwriter.png')} style={styles.headerLogo} />
        </View>

        {/* Tabs for Profile and Chats */}
        <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginBottom: 16 }}>
            <Pressable
                style={{ paddingVertical: 12, paddingHorizontal: 24, borderRadius: 24 }}
                onPress={() => setActiveTab('Profile')}
            >
                <Text style={{ color: activeTab === 'Profile' ? colors.primary : colors.secondary }}>Profile</Text>
                {activeTab === 'Profile' && (
                    <View style={{ height: 2, backgroundColor: colors.primary, marginTop: 4 }} /> // Underline
                )}
            </Pressable>
            <Pressable
                style={{ paddingVertical: 12, paddingHorizontal: 24, borderRadius: 24 }}
                onPress={() => setActiveTab('Chats')}
            >
                <Text style={{ color: activeTab === 'Chats' ? colors.primary : colors.secondary }}>Chats</Text>
                {activeTab === 'Chats' && (
                    <View style={{ height: 2, backgroundColor: colors.primary, marginTop: 4 }} /> // Underline
                )}
            </Pressable>
        </View>

        {/* Content Based on Active Tab */}
        <ScrollView style={{ flex: 1, paddingHorizontal: 32 }}>
        {/* Profile Tab Content */}
            {activeTab === 'Profile' ? (
            <View>
                {/* Logo URL*/}
                <FieldWithDescription
                label="Logo URL:"
                description="Enter the URL for your company logo (e.g., .png or .jpg)."
                value={logoUrl}
                onChangeText={setLogoUrl}
                placeholder="Enter logo URL"
                colors={colors}
                />
                {/* Website URL*/}
                <FieldWithDescription
                label="Website URL:"
                description="Provide the URL of your website to gather product info."
                value={websiteUrl}
                onChangeText={setWebsiteUrl}
                placeholder="Enter website URL"
                colors={colors}
                />
                {/* SignUp URL*/}
                <FieldWithDescription
                label="Signup URL:"
                description="Enter the URL where users can sign up for your service."
                value={signupUrl}
                onChangeText={setSignupUrl}
                placeholder="Enter signup URL"
                colors={colors}
                />

                {/* GTM ID */}
                <FieldWithDescription
                label="Google Tag Manager ID:"
                description="Input your Google Tag Manager ID (e.g., GTM-XXXXXX)."
                value={gtmId}
                onChangeText={setGtmId}
                placeholder="Enter GTM ID"
                colors={colors}
                />

                {/* AI Description */}
                <FieldWithDescription
                label="AI Description:"
                description="Describe how you want the AI to respond."
                value={aiDescription}
                onChangeText={setAiDescription}
                placeholder="Enter AI description"
                colors={colors}
                />
                    
                {/* AI Questions*/}
                <DynamicFieldWithDescription
                    label="AI Questions"
                    description="List the questions the AI should be able to answer."
                    data={aiQuestions}
                    onChange={setAiQuestions}
                    placeholder="Enter a question"
                    colors={colors}
                    addItem={addQuestion}
                    deleteItem={(index) => deleteItem(index, setAiQuestions, aiQuestions)}
                />

                {/* Products */}
                <DynamicFieldWithDescription
                    label="Products"
                    description="Enter the names or short descriptions of your products."
                    data={products}
                    onChange={setProducts}
                    placeholder="Enter a product"
                    colors={colors}
                    addItem={addProduct}
                    deleteItem={(index) => deleteItem(index, setProducts, products)}
                />

                {/* Lead Scoring */}
                <DynamicFieldWithDescription
                    label="Lead Scoring"
                    description="Explain how you rate potential customers to find the best ones interested in your products."
                    data={leadScoring}
                    onChange={setLeadScoring}
                    placeholder="Enter scoring criteria"
                    colors={colors}
                    addItem={addLeadScoring}
                    deleteItem={(index) => deleteItem(index, setLeadScoring, leadScoring)}
                />

                <Pressable 
                    onPress={handleSave}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={[
                    styles.saveButton, 
                    { backgroundColor: isHovered ? colors.secondary : colors.primary }
                    ]}
                >
                    <Text style={styles.saveButtonText}>Save</Text>
                </Pressable>

            </View>
            
            ) : ( 
                // Chats tab content
                <View style={{ flexDirection: 'row', flex: 1 }}>
                    {/* Left: Chat previews */}
                    <ScrollView style={{ flex: 1, marginRight: 16 }}>
                        <FlatList
                            data={chats}
                               keyExtractor={(item) => item.chatId.toString()}
                            renderItem={renderChatItem}
                        />
                    </ScrollView>

                    {/* Right: Detailed view of the selected chat */}
                        <View style={{ flex: 2, padding: 16, backgroundColor: colors.tertiary, borderRadius: 8 }}>
                            {selectedChat ? (
                                <>
                                    <Pressable
                                        style={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            paddingVertical: 8,
                                            paddingHorizontal: 12,
                                            backgroundColor: colors.primary,
                                            borderRadius: 20,
                                            zIndex: 10,

                                        }}
                                        onPress={() => setSelectedChat(null)}
                                    >
                                        <Text style={{ color: "#fff" }}>X</Text>
                                    </Pressable>
                                    <Text style={{ color: colors.primary, fontSize: 18 }}>Chat ID: {selectedChat.chatId}</Text>
                                    <Text style={{ color: "#2F4858" }}>{selectedChat.conversation}</Text>
                                </>
                            ) : (
                                <Text style={{ color: colors.secondary, fontSize: 14 }}>Select a chat to see the full conversation.</Text>
                            )}
                        </View>
                    </View>
                )}
            </ScrollView>
        </View>
    );
};
export default connect(mapStateToProps)(UserSetup);
