import React, { useState, useRef, useEffect, } from 'react'
import { View, FlatList, } from 'react-native'
import { connect } from 'react-redux'
import TextInput from '../components/TextInput'
import Message from '../components/Message'
import { ReactComponent as SendIcon } from '../assets/icons/send.svg'
import { prompt, evaluate, } from '../api'
import { setMessage, } from '../state/actions/ChatActions'
import { v4 as uuidv4, } from 'uuid'

const mapStateToProps = (state) => ({
  systemStore: state.systemStore,
  chatStore: state.chatStore,
})

const mapDispatchToProps = {
  setMessage,
}

function Home({ systemStore, chatStore, setMessage, }) {
  const { colors, } = systemStore

  const chatRef = useRef()

  const [chatId, setChatId] = useState(null)
  const [precursors, setPrecursors] = useState(systemStore.source.precursors)
  const [question, setQuestion] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (systemStore.source.precursors.length === precursors.length) setMessage({ message: precursors[0].message, userId: 'bot', })
  }, [])

  useEffect(() => {
    chatRef.current.scrollToOffset({ offset: chatStore.messages.length * 100000, animated: true, })
  }, [chatStore.messages])

  const enterMessage = async () => {
    setLoading(true)
    let useChatId = null
    if (!chatId) {
      useChatId = uuidv4()
      setChatId({ chatId: useChatId, })
    } else useChatId = chatId
    setMessage({ message: question, userId: 'user', })
    if (precursors.length) {
      let nextPrecursors = precursors

      let answer
      switch(true) {
        case systemStore.source.id === 'few':
          break
        case systemStore.source.id === 'lifeline':
          answer = await evaluate(useChatId, systemStore.source.id, question, precursors[0].message)
          setMessage({ message: answer, userId: 'bot', })
          break
        case systemStore.source.id === 'grantwriter':
          const userMessages = [...chatStore.messages.filter(i => i.userId === 'user'), { message: question, userId: 'user', }]
          if (userMessages.length === 5) {
            answer = await evaluate(useChatId, systemStore.source.id, question, precursors[0].message)
            setMessage({ message: answer, userId: 'bot', })
          }
          break
        default:
          break
      }

      if (precursors[0].message === chatStore.messages[0].message) nextPrecursors.shift()
      setMessage({ message: precursors[0].message, userId: 'bot', })
      setPrecursors(nextPrecursors.slice(1))
    } else {
      const answer = await prompt(useChatId, systemStore.source.id, question)
      setMessage({ message: answer, userId: 'bot', })
    }
    setQuestion('')
    setLoading(false)
  }

  return (
    <>
      <View style={{position: 'absolute', width: '100%', height: '100%',}}>
        <View
          style={{
            flex: 0, flexDirection: 'row', paddingVertical: 32, justifyContent: 'center', alignItems: 'center',
            borderBottomWidth: 3, borderColor: colors.secondary, backgroundColor: colors.tertiary,
          }}
        >
          <img src={systemStore.source.logo} style={{width: 300,}} />
        </View>

        <View style={{flex: 1, paddingHorizontal: 32, width: '100%', maxWidth: 1024, alignSelf: 'center',}}>
          <FlatList
            ref={chatRef}
            data={chatStore.messages}
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{paddingBottom: 128, paddingTop: 32,}}
            renderItem={({ item, }) => <Message systemStore={systemStore} item={item} />}
          />
        </View>

        <View
          style={{
            position: 'absolute', bottom: 0, width: '100%', paddingHorizontal: 64, paddingBottom: 32, paddingTop: 8,
            justifyContent: 'center', alignItems: 'center',
            backgroundColor: 'rgba(255,255,255,0.8)', backdropFilter: 'blur(10px)',
          }}
        >
          <TextInput
            systemStore={systemStore}
            value={question}
            setValue={setQuestion}
            exceedChars={0}
            limitChars={1024}
            loading={loading}
            disable={!precursors.length}
            placeholder={!precursors.length ? 'Chat ended.' : 'Chat...'}
            EndIcon={SendIcon}
            onPressEnd={enterMessage}
          />
        </View>
      </View>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
