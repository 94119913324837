import React, { useState } from 'react';
import { View, Text, TextInput, Pressable, FlatList } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const DynamicFieldWithDescription = ({
  label,
  description,
  data,
  onChange,
  placeholder,
  colors,
  addItem,
  deleteItem,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <View>
      {/* Fixed Title and Description */}
      <View style={{ marginBottom: 10 }}>
        <Text style={{ fontSize: 18, color: colors.primary, paddingBottom: 5 }}>{label}</Text>
        <Text style={{ fontSize: 14, color: colors.secondary, paddingBottom: 5 }}>{description}</Text>
      </View>

      {/* Container for Inputs */}
      <FlatList
        data={data}
        renderItem={({ item, index }) => (
          <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 8 }}>
            <TextInput
              value={item}
              onChangeText={(text) => {
                const newData = [...data];
                newData[index] = text;
                onChange(newData);
              }}
              style={{
                height: 40,
                borderColor: '#ccc',
                borderWidth: 1,
                borderRadius: 8,
                paddingHorizontal: 8,
                paddingRight: 40, // Space for the delete button
                flex: 1, // Make input take the remaining space
              }}
              placeholder={placeholder}
            />
            <Pressable
              onPress={() => deleteItem(index)}
              style={{
                position: 'absolute',
                right: 8, // Position delete button on the right inside the input
                justifyContent: 'center',
                alignItems: 'center',
                height: 25,
                width: 25,
                borderRadius: 20,
                backgroundColor: '#ffe4e1',
              }}
            >
              <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
            </Pressable>
          </View>
        )}
        keyExtractor={(item, index) => index.toString()}
        contentContainerStyle={{ flexGrow: 1 }} // Allow FlatList to grow
      />

      {/* Add Field Button Below Description */}
      <Pressable
        onPress={addItem}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          padding: 4,
          backgroundColor: isHovered ? colors.secondary : colors.primary,
          borderRadius: 20,
          alignSelf: 'flex-start',
          marginBottom: 15,
        }}
      >
        <Text style={styles.addFieldText}> + </Text>
      </Pressable>
    </View>
  );
};

const styles = {
  addFieldText: {
    color: '#fff',
    fontSize: 18,
  },
};

export default DynamicFieldWithDescription;
