const INITIAL_STATE = {
  messages: [],
}

export const ChatActions = {
  SetMessage: 'SET_MESSAGE',
}

const chatStore = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ChatActions.SetMessage:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      }
    default:
      return state
  }
}

export default chatStore
