import React from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native';

const FieldWithDescription = ({ 
    label, 
    description, 
    value, 
    onChangeText, 
    placeholder, 
    colors
 }) => {
  return (
    <View style={styles.container}>
      {/* Title */}
      <Text style={[styles.title, { color: colors.primary }]}>{label}</Text>
      {/* Description */}
      <Text style={[styles.description, { color: colors.secondary }]}>{description}</Text>
      {/* Input Field */}
      <TextInput
        value={value}
        onChangeText={onChangeText}
        style={styles.textInput}
        placeholder={placeholder}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    paddingBottom: 5,
  },
  description: {
    fontSize: 14,
    paddingBottom: 10,
  },
  textInput: {
    height: 40,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 8,
  },
});

export default FieldWithDescription;
