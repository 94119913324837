import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native';
import { connect } from 'react-redux';
import CombinedStyles from './CombinedStyles';
import { baseUrl } from '../api/index';

const mapStateToProps = (state) => ({
  colors: state.systemStore.colors,
  fonts: state.systemStore.fonts,
});

function GoogleLogin({ colors }) {
  const styles = CombinedStyles(colors);

  const handleLogin = () => {
    window.location.href = `${baseUrl}/auth/google`;
  };

  return (
    <>
      <View style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <View style={styles.header}>
          <Image source={require('../assets/images/grantwriter.png')} style={styles.headerLogo} />
        </View>
        <View style={styles.googleLoginContainer}>
          <Text style={styles.googleLoginTitle}>Welcome to GrantWriter, please login to continue.</Text>
          <View style={styles.googleLoginButtonContainer}>
            <TouchableOpacity style={styles.googleLoginButton} onPress={handleLogin}>
              <View style={styles.googleLoginIconContainer}>
                <Image source={require('../assets/icons/google_icon.png')} style={styles.googleLoginIcon} />
              </View>
              <Text style={styles.googleLoginButtonText}>Sign in with Google</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </>
  );
};

export default connect(mapStateToProps)(GoogleLogin);
