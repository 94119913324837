import axios from 'axios';
import { baseUrl } from '../../api';

const crawlWebsite = async (url) => {
    try {
        const response = await axios.post(`${baseUrl}/api/crawl-url`, { websiteUrl: url });
        console.log('Crawling successful:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error crawling the website:', error);
        throw error;
    }
};

export { crawlWebsite };
